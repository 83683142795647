// npm
import React from 'react'
import { Box, BoxProps, styled } from '@mui/material'

const StyledFrame = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.dark,
  padding: theme.spacing(3),
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(4),
  },
  [theme.breakpoints.up('xl')]: {
    padding: theme.spacing(5),
  },
}))

const Frame = ({ ...props }: BoxProps) => {
  return <StyledFrame {...props} />
}

export default Frame
