// npm
import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { styled } from '@mui/material'

// components
import Frame from '@atoms/media/Frame'

interface Props {
  collection: Queries.DatoCmsBrandingMockupCollection
}

const StyledFrame = styled(Frame)(({ theme }) => ({
  ['&:not(:last-of-type)']: {
    marginBottom: theme.spacing(4),
  },
}))

const BrandingMockupCollection = ({ collection }: Props) => {
  return (
    <>
      {collection.mockups.map((item: Queries.DatoCmsFileField) => {
        return (
          <StyledFrame key={item.originalId}>
            <GatsbyImage image={item.gatsbyImageData} alt={item.alt} />
          </StyledFrame>
        )
      })}
    </>
  )
}

export default BrandingMockupCollection
